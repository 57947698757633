<template>
    <b-container fluid>
        <div class="row">
            <div class="col-md-12">
                <div class="card card-primary card-outline">
                    <div class="card-header">
                        <div class="card-title">
                            <h4><i class="fa fa-briefcase"></i>Neuen Kunden anlegen</h4>
                        </div>
                        <div class="card-tools">
                            <button type="button" class="mr-1 btn btn-primary btn-sm" @click="saveCustomer">
                                <i class="fas fa-save"></i> Speichern
                            </button>
                        </div>
                    </div>

                    <div class="card-body">
                        <form>
                        <fieldset class="p-2 border">
                            <legend class="w-auto">Stammdaten</legend>
                            <div class="row">
                                <div class="col-md-6">

                                    <div class="form-group row">
                                        <label for="distributor_id" class="control-label col-sm-2 col-form-label col-form-label-sm">Shop*</label>
                                        <div class="col-sm-9">
                                            <select name="distributor_id" id="distributor_id" class="form-control form-control-sm" v-model="form.company_id" :class="{'is-invalid': form.errors.has('company_id')}" @change="companyChanged">
                                                <option v-for="company in companies" :key="company.id" v-bind:value="company.id">{{company.name}}</option>
                                            </select>
                                            <has-error :form="form" field="company_id"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="first_name" class="control-label col-sm-2 col-form-label col-form-label-sm">Vorname*</label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control form-control-sm" name="first_name" id="first_name" :class="{'is-invalid': form.errors.has('first_name')}" v-model="form.first_name">
                                            <has-error :form="form" field="first_name"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="last_name" class="control-label col-sm-2 col-form-label col-form-label-sm">Nachname*</label>
                                        <div class="col-sm-9">
                                            <input type="text" name="last_name" id="last_name" class="form-control form-control-sm" v-model="form.last_name" :class="{'is-invalid': form.errors.has('last_name')}">
                                            <has-error :form="form" field="last_name"></has-error>
                                        </div>
                                    </div>


                                    <div class="form-group row">
                                        <label for="birthday" class="control-label col-sm-2 col-form-label col-form-label-sm">Geburtstag</label>
                                        <div class="col-sm-9">
                                            <input type="date" class="form-control form-control-sm" name="birthday" id="birthday" :class="{'is-invalid': form.errors.has('birthday')}" v-model="form.birthday">
                                            <has-error :form="form" field="birthday"></has-error>
                                        </div>
                                    </div>


                                    <div class="form-group row">
                                        <label for="discount" class="control-label col-sm-2 col-form-label col-form-label-sm">Rabatt</label>
                                        <div class="col-sm-9">
                                            <input type="number" class="form-control form-control-sm" name="discount" id="discount" :class="{'is-invalid': form.errors.has('discount')}" v-model="form.discount">
                                            <has-error :form="form" field="discount"></has-error>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">

                                    <div class="form-group row">
                                        <label for="customer_group_id" class="control-label col-sm-2 offset-sm-1 col-form-label col-form-label-sm">Kundengruppe*</label>
                                        <div class="col-sm-9">
                                            <select name="customer_group_id" id="customer_group_id" class="form-control form-control-sm" @change="changeGroup($event)" v-model="form.customer_group_id" :class="{'is-invalid': form.errors.has('customer_group_id')}">
                                                <option v-for="group in groups" :key="group.id" v-bind:value="group.id">{{group.description}}</option>
                                            </select>
                                            <has-error :form="form" field="customer_group_id"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="email" class="control-label col-sm-2 offset-sm-1 col-form-label col-form-label-sm">E-Mail*</label>
                                        <div class="col-sm-9">
                                            <input type="email" autocomplete="username" class="form-control form-control-sm" name="email" id="email" :class="{'is-invalid': form.errors.has('email')}" v-model="form.email">
                                            <has-error :form="form" field="email"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group row" v-if="useAdditionalInvoice == true">
                                        <label for="invoice_email" class="control-label col-sm-2 offset-sm-1 col-form-label col-form-label-sm">Rechnungs-E-Mail</label>
                                        <div class="col-sm-9">
                                            <input type="invoice_email" autocomplete="username" class="form-control form-control-sm" name="invoice_email" id="invoice_email" :class="{'is-invalid': form.errors.has('invoice_email')}" v-model="form.invoice_email">
                                            <has-error :form="form" field="invoice_email"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="password" class="control-label col-sm-2 offset-sm-1 col-form-label col-form-label-sm">Passwort*</label>
                                        <div class="col-sm-9">
                                            <div class="input-group">
                                                <input type="password" autocomplete="new-password" name="password" id="password" class="form-control form-control-sm" v-model="form.password" :class="{'is-invalid': form.errors.has('password')}">
                                                <div class="input-group-append" @click="generate">
                                                    <span class="input-group-text">
                                                            <i class="fas fa-sync"></i>
                                                    </span>
                                                </div>
                                                <has-error :form="form" field="password"></has-error>
                                            </div>
                                            
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="password_confirmation" class="control-label col-sm-2 offset-sm-1 col-form-label col-form-label-sm">Passwort wiederholen*</label>
                                        <div class="col-sm-9">
                                            <input type="password" autocomplete="new-password" name="password_confirmation" id="password_confirmation" class="form-control form-control-sm" v-model="form.password_confirmation" :class="{'is-invalid': form.errors.has('password_confirmation')}">
                                            <has-error :form="form" field="password_confirmation"></has-error>
                                        </div>
                                    </div>
                                    <div v-if="sendCustomerMail == 1">
                                        <label for="invoice_email" class="control-label col-sm-11 offset-sm-1 col-form-label col-form-label-sm text-danger">
                                            Kunde bekommt eine E-Mail, welche ein Passwort zurücksetzen Link beinhaltet.
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </fieldset>

                        <fieldset class="p-2 border">
                            <legend class="w-auto">Rechnungsadresse <span v-if="form.useShippingAddress == false">und Lieferadresse</span></legend>
                            <div class="row">
                                <div class="col-md-6">

                                    <div class="form-group row">
                                        <label for="street" class="control-label col-sm-2 col-form-label col-form-label-sm">Straße*</label>
                                        <div class="col-sm-9">
                                            <input type="text" autocomplete="address-line1" class="form-control form-control-sm" name="street" id="street" :class="{'is-invalid': form.errors.has('address.street')}" v-model="form.address.street">
                                            <has-error :form="form" field="address.street"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="house_number" class="control-label col-sm-2 col-form-label col-form-label-sm">Hausnummer*</label>
                                        <div class="col-sm-9">
                                            <input type="text" name="house_number" id="house_number" class="form-control form-control-sm" v-model="form.address.house_number" :class="{'is-invalid': form.errors.has('address.house_number')}">
                                            <has-error :form="form" field="address.house_number"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="zipcode" class="control-label col-sm-2 col-form-label col-form-label-sm">PLZ*</label>
                                        <div class="col-sm-9">
                                            <input type="text" autocomplete="postal-code" class="form-control form-control-sm" name="zipcode" id="zipcode" :class="{'is-invalid': form.errors.has('address.zipcode')}" v-model="form.address.zipcode">
                                            <has-error :form="form" field="address.zipcode"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="city" class="control-label col-sm-2 col-form-label col-form-label-sm">Stadt*</label>
                                        <div class="col-sm-9">
                                            <input type="text" autocomplete="address-line2" name="city" id="city" class="form-control form-control-sm" v-model="form.address.city" :class="{'is-invalid': form.errors.has('address.city')}">
                                            <has-error :form="form" field="address.city"></has-error>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="kostenstelle" class="control-label col-sm-2 col-form-label col-form-label-sm">Kostenstelle</label>
                                        <div class="col-sm-9">
                                            <input type="text" autocomplete="address-line2" name="kostenstelle" id="kostenstelle" class="form-control form-control-sm" v-model="form.address.kostenstelle" :class="{'is-invalid': form.errors.has('address.kostenstelle')}">
                                            <has-error :form="form" field="address.kostenstelle"></has-error>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="customSwitchShippingAddress" class="control-label col-sm-2 col-form-label col-form-label-sm">Abweichende Lieferadresse?</label>
                                        <div class="custom-control custom-switch">
                                            <input type="checkbox" class="custom-control-input" id="customSwitchShippingAddress" v-model="form.useShippingAddress">
                                            <label class="custom-control-label" for="customSwitchShippingAddress"></label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">

                                    <div class="form-group row">
                                        <label for="company" class="control-label col-sm-2 offset-sm-1 col-form-label col-form-label-sm">Firma</label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control form-control-sm" name="company" id="company" :class="{'is-invalid': form.errors.has('address.company')}" v-model="form.address.company">
                                            <has-error :form="form" field="address.company"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="department" class="control-label col-sm-2 offset-sm-1 col-form-label col-form-label-sm">Abteilung</label>
                                        <div class="col-sm-9">
                                            <input type="text" name="department" id="department" class="form-control form-control-sm" v-model="form.address.department" :class="{'is-invalid': form.errors.has('address.department')}">
                                            <has-error :form="form" field="address.department"></has-error>
                                        </div>
                                    </div>
                                    
                                    <div class="form-group row">
                                        <label for="ustid" class="control-label col-sm-2 offset-sm-1 col-form-label col-form-label-sm">Ust-Id.</label>
                                        <div class="col-sm-9">
                                            <input type="text" name="ustid" id="ustid" class="form-control form-control-sm" v-model="form.address.ustid" :class="{'is-invalid': form.errors.has('address.ustid')}">
                                            <has-error :form="form" field="address.ustid"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="phone" class="control-label col-sm-2 offset-sm-1 col-form-label col-form-label-sm">Telefon</label>
                                        <div class="col-sm-9">
                                            <input type="text" name="phone" id="phone" class="form-control form-control-sm" v-model="form.address.phone" :class="{'is-invalid': form.errors.has('address.phone')}">
                                            <has-error :form="form" field="address.phone"></has-error>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset class="p-2 border" v-if="form.useShippingAddress == true">
                            <legend class="w-auto">Lieferadresse</legend>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="shipping_first_name" class="control-label col-sm-2 col-form-label col-form-label-sm">Vorname*</label>
                                        <div class="col-sm-9">
                                            <input type="text" autocomplete="address-line1" class="form-control form-control-sm" name="shipping_first_name" id="shipping_first_name" :class="{'is-invalid': form.errors.has('shipping_address.first_name')}" v-model="form.shipping_address.first_name">
                                            <has-error :form="form" field="shipping_address.first_name"></has-error>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="shipping_last_name" class="control-label col-sm-2 col-form-label col-form-label-sm">Nachname*</label>
                                        <div class="col-sm-9">
                                            <input type="text" autocomplete="address-line1" class="form-control form-control-sm" name="shipping_last_name" id="shipping_last_name" :class="{'is-invalid': form.errors.has('shipping_address.last_name')}" v-model="form.shipping_address.last_name">
                                            <has-error :form="form" field="shipping_address.last_name"></has-error>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="shipping_addressstreet" class="control-label col-sm-2 col-form-label col-form-label-sm">Straße*</label>
                                        <div class="col-sm-9">
                                            <input type="text" autocomplete="address-line1" class="form-control form-control-sm" name="shipping_addressstreet" id="shipping_addressstreet" :class="{'is-invalid': form.errors.has('shipping_address.street')}" v-model="form.shipping_address.street">
                                            <has-error :form="form" field="shipping_address.street"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="shipping_addresshouse_number" class="control-label col-sm-2 col-form-label col-form-label-sm">Hausnummer*</label>
                                        <div class="col-sm-9">
                                            <input type="text" name="shipping_addresshouse_number" id="shipping_addresshouse_number" class="form-control form-control-sm" v-model="form.shipping_address.house_number" :class="{'is-invalid': form.errors.has('shipping_address.house_number')}">
                                            <has-error :form="form" field="shipping_address.house_number"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="shipping_addresszipcode" class="control-label col-sm-2 col-form-label col-form-label-sm">PLZ*</label>
                                        <div class="col-sm-9">
                                            <input type="text" autocomplete="postal-code" class="form-control form-control-sm" name="shipping_addresszipcode" id="shipping_addresszipcode" :class="{'is-invalid': form.errors.has('shipping_address.zipcode')}" v-model="form.shipping_address.zipcode">
                                            <has-error :form="form" field="shipping_address.zipcode"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="shipping_addresscity" class="control-label col-sm-2 col-form-label col-form-label-sm">Stadt*</label>
                                        <div class="col-sm-9">
                                            <input type="text" autocomplete="address-line2" name="shipping_addresscity" id="shipping_addresscity" class="form-control form-control-sm" v-model="form.shipping_address.city" :class="{'is-invalid': form.errors.has('shipping_address.city')}">
                                            <has-error :form="form" field="shipping_address.city"></has-error>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="shipping_addressKostenstelle" class="control-label col-sm-2 col-form-label col-form-label-sm">Kostenstelle</label>
                                        <div class="col-sm-9">
                                            <input type="text" autocomplete="address-line2" name="shipping_addressKostenstelle" id="shipping_addressKostenstelle" class="form-control form-control-sm" v-model="form.shipping_address.kostenstelle" :class="{'is-invalid': form.errors.has('shipping_address.kostenstelle')}">
                                            <has-error :form="form" field="shipping_address.kostenstelle"></has-error>
                                        </div>
                                    </div>

                                </div>

                                <div class="col-md-6">

                                    <div class="form-group row">
                                        <label for="shipping_addresscompany" class="control-label col-sm-2 offset-sm-1 col-form-label col-form-label-sm">Firma</label>
                                        <div class="col-sm-9">
                                            <input type="text" class="form-control form-control-sm" name="shipping_addresscompany" id="shipping_addresscompany" :class="{'is-invalid': form.errors.has('shipping_address.company')}" v-model="form.shipping_address.company">
                                            <has-error :form="form" field="shipping_address.company"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="shipping_addressdepartment" class="control-label col-sm-2 offset-sm-1 col-form-label col-form-label-sm">Abteilung</label>
                                        <div class="col-sm-9">
                                            <input type="text" name="shipping_addressdepartment" id="shipping_addressdepartment" class="form-control form-control-sm" v-model="form.shipping_address.department" :class="{'is-invalid': form.errors.has('shipping_address.department')}">
                                            <has-error :form="form" field="shipping_address.department"></has-error>
                                        </div>
                                    </div>
                                    
                                    <div class="form-group row">
                                        <label for="shipping_addressustid" class="control-label col-sm-2 offset-sm-1 col-form-label col-form-label-sm">Ust-Id.</label>
                                        <div class="col-sm-9">
                                            <input type="text" name="shipping_addressustid" id="shipping_addressustid" class="form-control form-control-sm" v-model="form.shipping_address.ustid" :class="{'is-invalid': form.errors.has('shipping_address.ustid')}">
                                            <has-error :form="form" field="shipping_address.ustid"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="shipping_addressphone" class="control-label col-sm-2 offset-sm-1 col-form-label col-form-label-sm">Telefon</label>
                                        <div class="col-sm-9">
                                            <input type="text" name="shipping_addressphone" id="shipping_addressphone" class="form-control form-control-sm" v-model="form.shipping_address.phone" :class="{'is-invalid': form.errors.has('shipping_address.phone')}">
                                            <has-error :form="form" field="shipping_address.phone"></has-error>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <input type="hidden" v-model="form.shipping_address.first_name">
                            <input type="hidden" v-model="form.shipping_address.last_name">
                        </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </b-container>
</template>

<script>
export default {
    name: "CustomersCreate",
    title: "Neuer Kunde",

    data(){
        return {
            useAdditionalInvoice: false,
            form: new window.Form({
                useShippingAddress: false,
                company_id: "",
                customer_group_id: "",
                first_name: "",
                last_name: "",
                email: "",
                password: "",
                password_confirmation: "",
                birthday: "",
                discount: "0",
                invoice_email: "",
                address:{
                    street: "",
                    house_number: "",
                    zipcode: "",
                    city: "",
                    company: "",
                    department: "",
                    ustid: "",
                    phone: "",
                    kostenstelle: "",
                },
                shipping_address: null
            }),
            companies:[],
            groups: [],
            sendCustomerMail: 0,
        }
    },
    watch: {
        'form.useShippingAddress': {
            handler() {
                if(this.form.useShippingAddress == true)
                {
                    this.form.shipping_address = {
                        street: null,
                        house_number: null,
                        zipcode: null,
                        city: null,
                        company: "",
                        department: "",
                        ustid: "",
                        phone: "",
                        kostenstelle: "",
                        first_name: null,
                        last_name: null,
                    };
                } else
                {
                    this.form.shipping_address = {};
                }
            },
            deep: true,
        }
    },

    methods: {
        changeGroup(event){
            // console.log(event.target.value); 
            var group = this.groups.find(item => item.id == event.target.value); 
            // console.log(group);
            if(group.use_invoice_email == 0)
            {
                this.useAdditionalInvoice = false;
            } else
            {
                this.useAdditionalInvoice = true;
            }
            // if(group.tax == 1)
            // {
            //     this.useAdditionalInvoice = false;
            // }
            // else{
            //     this.useAdditionalInvoice = true;
            // }
        },

        generate () {  
            let CharacterSet = '';
            let password = '';
            
            CharacterSet += 'abcdefghijklmnopqrstuvwxyz';
            CharacterSet += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            CharacterSet += '0123456789';
            CharacterSet += '![]{}()%&*$#^<>~@|';

            
            for(let i=0; i < 16; i++) {
                password += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length));
            }
            this.form.password = password;
            this.form.password_confirmation = password;
            this.$swal({
                        icon: "success",
                        title: "Zufallspasswort wurde generiert",
                    });
        },

        saveCustomer(){
            this.$Progress.start();
            this.form.address.first_name = this.form.first_name;
            this.form.address.last_name = this.form.last_name;

            this.form
                .post("/customers")
                .then((response) => {
                    this.$swal({
                        icon: "success",
                        title: "Kunde erfolgreich hinzugefügt",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.$Progress.finish();
                    this.$router.push({name: 'customers.details', params : { id: response.data.data.id}});
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },
        companyChanged(event){
            const result = this.companies.find( company => company.id == event.target.value);
            //console.log(result);
            this.groups = result.groups;
            this.sendCustomerMail = result.customer_create_mail
        },
        loadCompanies(){
            this.axios
                .get("/companies")
                .then((response) => {
                    this.companies = response.data.data;
                    this.form.company_id = this.companies[0].id;
                    this.groups = this.companies[0].groups;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        }
    },

    created(){
        this.loadCompanies();
        this.form.password = 'hofladen';
        this.form.password_confirmation = 'hofladen';
    }

}
</script>

<style>

</style>